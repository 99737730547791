import ReactMarkdown from 'react-markdown';
import { usePage } from '@inertiajs/react';
import { PageProps } from '@/types';
import { 
    CmsBlock, 
    CmsBlocks, 
    CmsHeaderBlock, 
    CmsCollectionBlock,
    CmsIconBoxBlock,
    CmsPageHeaderBlock,
    getHeaderBlock,
    getCollectionBlock,
    getIconBoxBlock,
    getPageHeaderBlock,
    getBlock
} from '../types/cms';

export function stripTagsNewLinesAndTrim(content: string): string {
    return content.replace(/<[^>]*>?/g, '').replace(/\n/g, '').trim();
}

/**
 * Hook to get raw string content
 */
export function useCmsStringRaw(slug: string, fallback: string = ''): string {
    const { cms } = usePage<PageProps>().props;
    const text = cms?.strings?.find(t => t.slug === slug);
    if (!text) {
        console.warn(`Text with slug "${slug}" not found`);
        return fallback;
    }
    return stripTagsNewLinesAndTrim(text.content);
}

/**
 * Hook to get rendered string content
 */
export function useCmsString(slug: string, fallback?: string): JSX.Element | null {
    const { cms } = usePage<PageProps>().props;
    const text = cms?.strings?.find(t => t.slug === slug);
    if (!text) {
        console.warn(`Text with slug "${slug}" not found`);

        if (fallback) {
            return <>{fallback}</>;
        }
        
        return null;
    }

    return (
        <ReactMarkdown>
            {text.content}
        </ReactMarkdown>
    );
}

/**
 * Hook to access CMS blocks
 */
export function useCmsBlocks(): CmsBlocks {
    const { cms } = usePage<PageProps>().props;
    
    if (!cms || !cms.blocks) {
        return {} as CmsBlocks;
    }
    
    return cms.blocks;
}

/**
 * Hook to get a specific CMS block by slug
 */
export function useCmsBlock<T extends CmsBlock>(slug: string): T | null {
    const blocks = useCmsBlocks();
    return getBlock<T>(blocks, slug);
}

/**
 * Hook to get a header block by slug
 */
export function useHeaderBlock(slug: string): CmsHeaderBlock | null {
    const blocks = useCmsBlocks();
    return getHeaderBlock(blocks, slug);
}

/**
 * Hook to get a collection block by slug
 */
export function useCollectionBlock(slug: string): CmsCollectionBlock | null {
    const blocks = useCmsBlocks();
    return getCollectionBlock(blocks, slug);
}

/**
 * Hook to get an icon box block by slug
 */
export function useIconBoxBlock(slug: string): CmsIconBoxBlock | null {
    const blocks = useCmsBlocks();
    return getIconBoxBlock(blocks, slug);
}

/**
 * Hook to get a page header block by slug
 */
export function usePageHeaderBlock(slug: string): CmsPageHeaderBlock | null {
    const blocks = useCmsBlocks();
    return getPageHeaderBlock(blocks, slug);
}
