import React, { useState } from 'react';
import { Facebook, Instagram } from 'lucide-react';
import axios from 'axios';
import { useCmsString } from '@/hooks/cms';
import { strings } from '@/types/cms';

const Footer: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });
    const [status, setStatus] = useState<{
        type: 'success' | 'error' | null;
        message: string;
    }>({ type: null, message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setStatus({ type: null, message: '' });

        try {
            await axios.post('/api/newsletter/subscribe', formData);
            setStatus({
                type: 'success',
                message: 'Thank you for subscribing to our newsletter!'
            });
            setFormData({ name: '', email: '' });
        } catch (error: any) {
            if (error.response?.data?.errors) {
                const errorMessages = Object.values(error.response.data.errors).flat();
                setStatus({
                    type: 'error',
                    message: errorMessages.join('. ')
                });
            } else {
                setStatus({
                    type: 'error',
                    message: 'An error occurred. Please try again later.'
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <>
            <footer className="relative bg-brand bg-opacity-90">
                <div className="absolute inset-0 bg-[url('/images/bone-trans.png')] bg-repeat opacity-100"></div>
                <div className="container relative mx-auto px-4 py-12">
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mb-12">
                        {/* Logo and Strapline - 2 columns */}
                        <div className="md:col-span-2">
                            <img src="/images/logo-white-rec.png" alt="Grow Walkies Logo" className="w-60 mb-8" />
                            <p className="text-sm text-white">{useCmsString(strings.CMS_FOOTER_STRAPLINE, 'Providing safe and secure exercise fields for your furry friends since 2021. The UK\'s largest network of secure dog walking fields.')}</p>
                        </div>

                        {/* Use Cases */}
                        <div>
                            <h3 className="text-lg text-white font-semibold mb-4">{useCmsString(strings.CMS_FOOTER_LOCATIONS, 'Locations')}</h3>
                            <ul className="text-sm text-white">
                                <li className="mb-2"><a href="/location/amwell" className="hover:text-white">Amwell</a></li>
                                <li className="mb-2"><a href="/location/buntingford" className="hover:text-white">Buntingford</a></li>
                                <li className="mb-2"><a href="/location/chelmsford" className="hover:text-white">Chelmsford</a></li>
                                <li className="mb-2"><a href="/location/chiddingfold" className="hover:text-white">Chiddingfold</a></li>
                                <li className="mb-2"><a href="/location/colchester" className="hover:text-white">Colchester</a></li>
                                <li className="mb-2"><a href="/location/epping" className="hover:text-white">Epping</a></li>
                                <li className="mb-2"><a href="/location/graveley" className="hover:text-white">Graveley</a></li>
                                <li className="mb-2"><a href="/location/hertford" className="hover:text-white">Hertford</a></li>
                                <li className="mb-2"><a href="/location/north-london" className="hover:text-white">North London</a></li>
                                <li className="mb-2"><a href="/location/south-mimms" className="hover:text-white">South Mimms</a></li>
                                <li className="mb-2"><a href="/location/tewin" className="hover:text-white">Tewin</a></li>
                            </ul>
                        </div>

                        {/* Company */}
                        <div>
                            <h3 className="text-lg text-white font-semibold mb-4">{useCmsString(strings.CMS_FOOTER_COMPANY, 'Company')}</h3>
                            <ul className="text-sm text-white">
                                <li className="mb-2"><a href="/about" className="hover:text-white">{useCmsString(strings.CMS_FOOTER_ABOUT_US, 'About Us')}</a></li>
                                <li className="mb-2"><a href="/how-it-works" className="hover:text-white">{useCmsString(strings.CMS_MENU_HOW, 'How It Works')}</a></li>
                                <li className="mb-2"><a href="/start-a-field" className="hover:text-white">{useCmsString(strings.CMS_MENU_START, 'Start A Field')}</a></li>
                                <li className="mb-2"><a href="/gift-cards" className="hover:text-white">{useCmsString(strings.CMS_MENU_GIFT_CARDS, 'Gift Cards')}</a></li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-lg text-white font-semibold mb-4">{useCmsString(strings.CMS_FOOTER_CONTACT, 'Contact')}</h3>
                            <ul className="text-sm text-white">
                                <li className="mb-2"><a href="tel:08002606915" className="hover:text-white">0800 260 6915</a></li>
                                <li className="mb-2"><a href="mailto:info@grow-walkies.com" className="hover:text-white">info@grow-walkies.com</a></li>
                            </ul>
                        </div>
                    </div>

                    {/* Subscribe Section */}
                    <div className="border-t border-white/20 pt-12">
                        <div className="max-w-xl mx-auto text-center">
                            <h3 className="text-2xl text-white font-semibold mb-6">{useCmsString(strings.CMS_FOOTER_NEWSLETTER_TITLE, 'Subscribe to Our Newsletter')}</h3>
                            <p className="text-white/80 mb-8">{useCmsString(strings.CMS_FOOTER_NEWSLETTER_SUBTITLE, 'Stay updated with our latest news and special offers')}</p>
                            {status.message && (
                                <div className={`mb-4 p-3 rounded-lg ${
                                    status.type === 'success' ? 'bg-green-500/20 text-green-100' : 'bg-red-500/20 text-red-100'
                                }`}>
                                    {status.type === 'success' 
                                        ? useCmsString(strings.CMS_FOOTER_NEWSLETTER_SUCCESS, status.message)
                                        : useCmsString(strings.CMS_FOOTER_NEWSLETTER_ERROR, status.message)
                                    }
                                </div>
                            )}
                            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder={useCmsString(strings.CMS_FOOTER_NEWSLETTER_NAME, 'Name')?.props?.children}
                                    className="flex-1 px-4 py-3 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-white/50"
                                    disabled={isLoading}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder={useCmsString(strings.CMS_FOOTER_NEWSLETTER_EMAIL, 'Email')?.props?.children}
                                    className="flex-1 px-4 py-3 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-white/50"
                                    disabled={isLoading}
                                />
                                <button
                                    type="submit"
                                    className="px-8 py-3 bg-white text-brand font-semibold rounded-lg hover:bg-gray-100 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                                    disabled={isLoading}
                                >
                                    {isLoading 
                                        ? useCmsString(strings.CMS_FOOTER_NEWSLETTER_SUBMITTING, 'Subscribing...')?.props?.children 
                                        : useCmsString(strings.CMS_FOOTER_NEWSLETTER_SUBMIT, 'Subscribe')?.props?.children
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Secondary Footer */}
            <footer className="bg-pink text-white py-8">
                <div className="container mx-auto px-4">
                    {/* Legal Links */}
                    <div className="flex justify-center space-x-8 mb-6">
                        <a href="/privacy" className="text-sm hover:text-white">{useCmsString(strings.CMS_FOOTER_PRIVACY, 'Privacy Policy')}</a>
                        <a href="/terms" className="text-sm hover:text-white">{useCmsString(strings.CMS_FOOTER_TERMS, 'Terms')}</a>
                        <a href="/legal" className="text-sm hover:text-white">{useCmsString(strings.CMS_FOOTER_LEGAL, 'Legal')}</a>
                        <a href="/noise" className="text-sm hover:text-white">{useCmsString(strings.CMS_FOOTER_NOISE, 'Noise Complaints')}</a>
                    </div>

                    {/* Social Icons */}
                    <div className="flex justify-center space-x-4">
                        <a href="https://www.facebook.com/growwalkies" target='_blank' className="text-white hover:text-white">
                            <Facebook className="w-6 h-6" />
                        </a>
                        <a href="https://www.instagram.com/grow.walkies/" target='_blank' className="text-white hover:text-white">
                            <Instagram className="w-6 h-6" />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;