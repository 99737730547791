import React, { useState, useEffect } from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react';
import { Button } from "@/Components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/Components/ui/dropdown-menu";
import { User, Dog, Calendar, CreditCard, DoorOpen, User2, Menu, X } from 'lucide-react';
import { User as UserType } from '@/types'
import ApplicationLogoNormal from '@/Components/ApplicationLogoNormal';
import { useCmsString } from '@/hooks/cms';
import { strings } from '@/types/cms';

interface HeaderProps {
    user?: UserType | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [bannerHeight, setBannerHeight] = useState(0);
    const faqsEnabled = false;

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // Handle scroll and measure banner height
    useEffect(() => {
        const updateScrollState = () => {
            // Consider "scrolled" after 100px to give some buffer
            setIsScrolled(window.scrollY > 100);
        };

        const updateBannerHeight = () => {
            const banner = document.getElementById('maintenance-banner');
            if (banner) {
                setBannerHeight(banner.offsetHeight);
            }
        };

        // Initial measurements
        updateScrollState();
        updateBannerHeight();
        
        // Update on scroll and resize
        window.addEventListener('scroll', updateScrollState);
        window.addEventListener('resize', updateBannerHeight);
        
        return () => {
            window.removeEventListener('scroll', updateScrollState);
            window.removeEventListener('resize', updateBannerHeight);
        };
    }, []);

    return (
        <>
            {/* Maintenance Banner - starts in normal flow, becomes fixed on scroll */}
            <div 
                id="maintenance-banner"
                className={`w-full hidden bg-yellow-500 text-black py-6 text-center text-base font-medium ${
                    isScrolled ? 'fixed top-0 left-0 right-0 z-[60]' : ''
                }`}
            >
                <p className="px-4">Please note, our website will be unavailable from 10pm tomorrow, 4th of March until 4am on the 5th of March. We apologize for any inconvenience caused.</p>
            </div>

            {/* Spacer div that takes up space when banner becomes fixed */}
            {isScrolled && <div style={{ height: `${bannerHeight}px` }}></div>}

            {/* Add a spacer div for the header when it becomes fixed */}
            {isScrolled && <div style={{ height: '60px' }}></div>}

            {/* Header - starts in normal flow, becomes fixed on scroll */}
            <header 
                className={`w-full bg-white shadow-md ${
                    isScrolled ? 'fixed left-0 right-0 z-50' : ''
                }`}
                style={isScrolled ? { top: `${bannerHeight}px` } : {}}
            >
                <div className="flex items-center justify-between px-4 py-2">
                    {/* Logo */}
                    <div className="flex items-center">
                        <a href='/'>
                            <ApplicationLogoNormal className="w-24 pb-1 ml-2" />
                        </a>
                    </div>

                    {/* Desktop Navigation Menu and Buttons */}
                    <div className="hidden md:flex items-center space-x-8">
                        <nav className="flex space-x-6">
                            <a href="/" className="text-gray-600 hover:text-gray-900 text-sm">{useCmsString(strings.CMS_MENU_HOME, 'Home')}</a>
                            <a href="/how-it-works" className="text-gray-600 hover:text-gray-900 text-sm">{useCmsString(strings.CMS_MENU_HOW)}</a>
                            {faqsEnabled && (
                                <a href="/faqs" className="text-gray-600 hover:text-gray-900 text-sm">{useCmsString(strings.CMS_MENU_FAQS, 'FAQs')}</a>
                            )}
                            <a href="/locations" className="text-gray-600 hover:text-gray-900 text-sm">{useCmsString(strings.CMS_MENU_FIELDS)}</a>
                            <a href="/gift-cards" className="text-gray-600 hover:text-gray-900 text-sm">{useCmsString(strings.CMS_MENU_GIFT_CARDS)}</a>
                        </nav>

                        <div className="flex items-center space-x-4">
                            <Link href="/locations">
                                <Button className="bg-brand hover:bg-dark text-white py-1 px-4 h-8 text-sm">{useCmsString(strings.CMS_BOOK_NOW, 'Book Now')}</Button>
                            </Link>
                            <div className="h-6 w-px bg-gray-300"></div>
                            {user ? (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="outline" className="flex items-center space-x-2 cursor-pointer py-1 px-3 h-8">
                                            <User className="w-4 h-4" />
                                            <span className="text-sm">{user.name}</span>
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-56">
                                        <Link href="/account/membership">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <User className="mr-2 h-4 w-4" />
                                                <span>{useCmsString(strings.CMS_MENU_PROFILE, 'My Profile')}</span>
                                            </DropdownMenuItem>
                                        </Link>
                                        <Link href="/account/dogs">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <Dog className="mr-2 h-4 w-4" />
                                                <span>{ useCmsString(strings.CMS_MENU_DOGS, 'My Dogs')}</span>
                                            </DropdownMenuItem>
                                        </Link>
                                        <Link href="/account/history">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <Calendar className="mr-2 h-4 w-4" />
                                                <span>{useCmsString(strings.CMS_MENU_BOOKINGS, 'My Bookings')}</span>
                                            </DropdownMenuItem>
                                        </Link>
                                        <Link href="/account/methods">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <CreditCard className="mr-2 h-4 w-4" />
                                                <span>{useCmsString(strings.CMS_MENU_PAYMENT_METHODS, 'My Payment Methods')}</span>
                                            </DropdownMenuItem>
                                        </Link>

                                        {user.type !== 'customer' && (
                                            <>
                                                <DropdownMenuSeparator />
                                                <Link href="/dashboard">
                                                    <DropdownMenuItem className="cursor-pointer">
                                                        <User2 className="mr-2 h-4 w-4" />
                                                        <span>{useCmsString(strings.CMS_MENU_GO_TO_ADMIN, 'Go To Admin')}</span>
                                                    </DropdownMenuItem>
                                                </Link>
                                            </>
                                        )}

                                        {user.is_franchise_user && (
                                            <>
                                                <DropdownMenuSeparator />
                                                <Link href="/dashboard">
                                                    <DropdownMenuItem className="cursor-pointer">
                                                        <User2 className="mr-2 h-4 w-4" />
                                                        <span>{useCmsString(strings.CMS_MENU_GO_TO_FRANCHISE, 'Go To Franchise Admin')}</span>
                                                    </DropdownMenuItem>
                                                </Link>
                                            </>
                                        )}

                                        <DropdownMenuSeparator />
                                        <Link href="/logout">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <DoorOpen className="mr-2 h-4 w-4" />
                                                <span>{useCmsString(strings.CMS_MENU_LOGOUT, 'Logout')}</span>
                                            </DropdownMenuItem>
                                        </Link>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            ) : (
                                <>
                                    <Link href="/login">
                                        <Button variant="outline" className=" bg-dark hover:bg-pink hover:text-white text-white py-1 px-3 h-8 text-sm">{useCmsString(strings.CMS_LOGIN, 'Login')}</Button>
                                    </Link>
                                    <Link href="/register">
                                        <Button variant="outline" className="py-1 hover:bg-dark hover:text-white border-dark px-3 h-8 text-sm">{useCmsString(strings.CMS_REGISTER, 'Register')}</Button>
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Mobile Book Now and Menu Button */}
                    <div className="md:hidden flex items-center gap-2">
                        <Link href="/locations">
                            <Button className="bg-brand hover:bg-dark text-white py-1 px-4 h-8 text-sm">{useCmsString(strings.CMS_BOOK_NOW, 'Book Now')}</Button>
                        </Link>
                        <button
                            className="p-2 text-gray-600"
                            onClick={toggleMobileMenu}
                        >
                            {isMobileMenuOpen ? (
                                <X className="h-6 w-6" />
                            ) : (
                                <Menu className="h-6 w-6" />
                            )}
                        </button>
                    </div>
                </div>
            </header>

            {/* Mobile Menu */}
            <div className={`md:hidden fixed inset-0 bg-brand z-50 transition-transform duration-300 ease-in-out ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                {/* Close button */}
                <button
                    onClick={toggleMobileMenu}
                    className="absolute top-4 right-4 text-white p-2"
                >
                    <X className="h-6 w-6" />
                </button>

                <div className="flex flex-col h-full pt-14">
                    <nav className="flex flex-col space-y-2 px-4 overflow-y-auto max-h-[calc(100vh-3.5rem)]">
                        <a href="/how-it-works" className="text-white text-lg py-2 border-b border-white/20">
                            {useCmsString(strings.CMS_MENU_HOW, 'How It Works')}
                        </a>
                        {faqsEnabled && (
                            <a href="/faqs" className="text-white text-lg py-2 border-b border-white/20">
                                {useCmsString(strings.CMS_MENU_FAQS, 'FAQs')}
                            </a>
                        )}
                        <a href="/locations" className="text-white text-lg py-2 border-b border-white/20">
                            {useCmsString(strings.CMS_MENU_FIELDS, 'Our Fields')}
                        </a>
                        <a href="/gift-cards" className="text-white text-lg py-2 border-b border-white/20">
                            {useCmsString(strings.CMS_MENU_GIFT_CARDS, 'Gift Cards')}
                        </a>
                        <a href="/start-a-field" className="text-white text-lg py-2 border-b border-white/20">
                            {useCmsString(strings.CMS_MENU_START, 'Start A Field')}
                        </a>

                        {user ? (
                            <>
                                <Link href="/account/membership" className="text-white text-lg py-2 border-b border-white/20">
                                    {useCmsString(strings.CMS_MENU_PROFILE, 'My Profile')}
                                </Link>
                                <Link href="/account/dogs" className="text-white text-lg py-2 border-b border-white/20">
                                    {useCmsString(strings.CMS_MENU_DOGS, 'My Dogs')}
                                </Link>
                                <Link href="/account/history" className="text-white text-lg py-2 border-b border-white/20">
                                    {useCmsString(strings.CMS_MENU_BOOKINGS, 'My Bookings')}
                                </Link>
                                <Link href="/account/methods" className="text-white text-lg py-2 border-b border-white/20">
                                    {useCmsString(strings.CMS_MENU_PAYMENT_METHODS, 'My Payment Methods')}
                                </Link>

                                {user.type !== 'customer' && (
                                    <Link href="/dashboard" className="text-white text-lg py-2 border-b border-white/20">
                                        {useCmsString(strings.CMS_MENU_GO_TO_ADMIN, 'Go To Admin')}
                                    </Link>
                                )}

                                {user.is_franchise_user && (
                                    <Link href="/franchisee" className="text-white text-lg py-2 border-b border-white/20">
                                        {useCmsString(strings.CMS_MENU_GO_TO_FRANCHISE, 'Go To Franchise Admin')}
                                    </Link>
                                )}

                                <Link href="/logout" className="text-white text-lg py-2 border-b border-white/20">
                                    {useCmsString(strings.CMS_MENU_LOGOUT, 'Logout')}
                                </Link>
                            </>
                        ) : (
                            <div className="flex flex-col space-y-3 mt-3 mb-6">
                                <Link href="/login">
                                    <Button className="w-full bg-white text-brand hover:bg-white/90">
                                        {useCmsString(strings.CMS_LOGIN, 'Login')}
                                    </Button>
                                </Link>
                                <Link href="/register">
                                    <Button className="w-full bg-dark hover:bg-dark/90">
                                        {useCmsString(strings.CMS_REGISTER, 'Register')}
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Header;
